import Card from "Atoms/Card";
import Gutters from "Atoms/Gutters";
import TextLink from "Atoms/TextLink";
import Typography from "Atoms/Typography";
import Panel from "Atoms/Panel";
const groups = [
  {
    title: "NextJS",
    links: [
      {
        href: "/clutch/rsc",
        title: "React Server Components (app dir)",
      },
    ],
  },
  {
    title: "Atoms",
    links: [
      {
        href: "/clutch/button",
        title: "Button",
      },
      {
        href: "/clutch/",
        title: "Nothing Here",
      },
      {
        href: "/clutch/",
        title: "Nothing Here",
      },
    ],
  },
  {
    title: "Molecules",
    links: [
      {
        href: "/clutch/loyalty",
        title: "Loyalty",
      },

    ],
  },
  {
    title: "Hooks",
    links: [
      {
        href: "/clutch/featureflags",
        title: "Feature Flags",
      },
    ],
  },
  {
    title: "Perf",
    links: [
      // {
      //   href: "/clutch/perf/renders/1-child-causes-render",
      //   title: "Child Causes Render of component with {children}",
      // },
    ],
  },
];

function Group({ title, links }) {
  return (
    <Card fill layer={0}>
      <Typography size={1.25} font={"heading"}>{title}</Typography>
      <div style={{ display: "flex", gap: "0.75rem" }}>
        {links.map(({ href, title }) => (
          <Card key={title + href} href={href}>{title}</Card>
        ))}
      </div>
    </Card>
  );
}

function Index() {
  return (
    <Panel layer={1} style={{ minHeight: "1000px", height: "100vh" }}>
      <Gutters>

        <Typography size={2} font={"heading"}>Clutch UI</Typography>

        {groups.map((grp) => (
          <Group key={grp.title} {...grp} />
        ))}
      </Gutters>
    </Panel>
  );
}

export default Index;
